import styled from "styled-components";

const SectionContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-content: flex-start;
`;

export default SectionContent;
