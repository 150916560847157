import styled from "styled-components";
import { grey800 as greyColor } from "material-ui/styles/colors";

const PaneNullStateP = styled.p`
  font-size: 0.8rem;
  margin-top: 0;
  color: ${greyColor};
`;

export default PaneNullStateP;
